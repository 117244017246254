import * as firebase from 'firebase';

// const firebaseConfigDev = {
//   apiKey: "AIzaSyBT_Iq4V7tIyvbvygxpT2MYUOq44-r_ggw",
//   authDomain: "v-car-park-dev.firebaseapp.com",
//   databaseURL: "https://v-car-park-dev.firebaseio.com",
//   projectId: "v-car-park-dev",
//   storageBucket: "v-car-park-dev.appspot.com",
//   messagingSenderId: "208060898852",
//   appId: "1:208060898852:web:8678f726f9f1ae2badc2b1",
//   measurementId: "G-6QTX1VJPND"
// };

// const firebaseConfig = {
//   apiKey: "AIzaSyCBtQn-mXXlTCM3dSp_SR_hNiqxb-ssw98",
//   authDomain: "v-car-park.firebaseapp.com",
//   databaseURL: "https://v-car-park.firebaseio.com",
//   projectId: "v-car-park",
//   storageBucket: "v-car-park.appspot.com",
//   messagingSenderId: "1032097431504",
//   appId: "1:1032097431504:web:1552c3fdf4f669ff6303d5",
//   measurementId: "G-LYRHH0JWJ9"
// };


//KB-Solution Prod
const firebaseConfig = {
  apiKey: "AIzaSyBjSmQwf4N775SSaFDEl3GGxe30M5TeJlE",
  authDomain: "kb-vcarpark.firebaseapp.com",
  projectId: "kb-vcarpark",
  storageBucket: "kb-vcarpark.appspot.com",
  messagingSenderId: "471649319768",
  appId: "1:471649319768:web:a53318a897f9b849f5c32d",
  measurementId: "G-JHRT0MKJEL"
};

//K-Product Prod
// const firebaseConfig = {
//   apiKey: "AIzaSyCaV0av_GcFe3JUCYsLY6nvSahcQi1N25E",
//   authDomain: "v-product.firebaseapp.com",
//   projectId: "v-product",
//   storageBucket: "v-product.appspot.com",
//   messagingSenderId: "1084158099555",
//   appId: "1:1084158099555:web:597abea93e2515ee653856",
//   measurementId: "G-RHNC3M9YMS"
// };

//K-Product Dev
// const firebaseConfig = {
//   apiKey: "AIzaSyAo8qixnlQZ2R4FSC5IS1bNHMt-q-R23wA",
//   authDomain: "v-product-dev.firebaseapp.com",
//   projectId: "v-product-dev",
//   storageBucket: "v-product-dev.appspot.com",
//   messagingSenderId: "465562133299",
//   appId: "1:465562133299:web:0f139422d89940c0261446",
//   measurementId: "G-F7RXNPYVXH"
// };
export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
